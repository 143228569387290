<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!-- 标签列表 -->
        <span style="font-size: 14px">
          {{ $t("label.tag.search.taglist") }}
        </span>
        <svg class="icon guanbi" aria-hidden="true" @click="gb">
          <use :href="'#icon-chahao'"></use>
        </svg>
        <div>
          <el-row>
            <!-- 搜索 -->
            <el-button class="button" size="mini" @click="search">
              {{ $t("label.quickbooks.searchs") }}</el-button
            >
            <!-- 清除 -->
            <el-button class="button" size="mini" @click="eliminate">
              {{ $t("label.delete.condition") }}</el-button
            >
          </el-row>
        </div>
      </div>
      <div>
        <div>
          <!-- 同时满足所有选中标签 -->
          <el-checkbox v-model="checked" style="word-break: break-word">
            {{ $t("label.tag.filter1") }}
          </el-checkbox>
          <!-- 管理标签 -->
          <el-link
            v-if="
              isManageTag === 'true' || isCreatePrivateTag === 'true'
                ? true
                : false
            "
            class="label"
            @click="manages"
          >
            {{ $t("label.tag.manage.button") }}
          </el-link>
        </div>
        <div class="tag">
          <el-tag
            :class="[status.indexOf(item.id) !== -1 ? 'border' : '']"
            v-for="(item, index) in tags"
            :key="item.id"
            :type="item.type"
            effect="plain"
            @click="select(item.id, index)"
            style="cursor: pointer"
            :style="{
              background:
                item.color === 'hong'
                  ? hong
                  : item.color === 'huang'
                  ? huang
                  : item.color === 'lan'
                  ? lan
                  : item.color === 'hui'
                  ? hui
                  : item.color === 'lv'
                  ? lv
                  : '',
              color: color,
            }"
          >
            {{ item.name }}
          </el-tag>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { GetTagSearchTags, GetTagGetManageTagPermission } from "./api.js";

export default {
  props: {
    objId: String,
    getViewListDataParams: Object,
    tabName: String,
  },
  created() {
    this.GetTagSearchTagss();
    this.manage();
  },
  data() {
    return {
      status: [],
      border: "border",
      color: "#fff",
      hong: "#F6817B",
      huang: "#FEC366",
      lan: "#62A5F3",
      hui: "#C8C6C6",
      lv: "#7cedcc",
      isLabelView: false,
      checked: false,
      // objId: this.objId,
      tags: [],
      isCreatePrivateTag: "", //创建私有标签权限
      isManageTag: "", //显示管理标签权限
    };
  },
  methods: {
    //查询所有标签
    async GetTagSearchTagss() {
      let params = {
        objId: this.objId,
      };
      let res = await GetTagSearchTags(params);
      this.tags = res.data;
    },
    gb() {
      this.$emit("gb", this.isLabelView);
    },
    select(id, index) {
      // if(this.status.indexOf[index])

      if (this.status.indexOf(id) === -1) {
        this.status.push(id);
      } else {
        this.status.forEach((item, index) => {
          if (item.indexOf(id) > -1) {
            this.status.splice(index, 1);
          }
        });
      }
    },
    //搜索
    async search() {
      let selectedFieldList = this.status;
      let str = "";
      for (let i = 0; i < selectedFieldList.length; i++) {
        if (i < selectedFieldList.length - 1) {
          str += selectedFieldList[i] + ",";
        } else {
          str += selectedFieldList[i];
        }
      }
      this.$emit("tagValue", str, this.checked);
    },

    //清除
    eliminate() {
      this.status = [];
      this.$parent.refresh();
    },
    //权限管理
    async manage() {
      let res = await GetTagGetManageTagPermission();
      this.isManageTag = res.data.isManageTag;
      this.isCreatePrivateTag = res.data.isCreatePrivateTag;
    },
    manages() {
      this.$router.push({
        path: "/commonObjects/tagList",
        query: { objId: this.objId, tabName: this.tabName },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .box-card {
  width: 264px;
  height: 100%;
  .el-card__body {
    height: 100%;
    overflow-y: auto;
  }
}

.clearfix span {
  width: 87px;
  height: 12px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
}

.guanbi {
  width: 14px;
  height: 14px;
  float: right;
  cursor: pointer;
}

.button {
  margin-top: 10px;
}

::v-deep .el-checkbox {
  white-space: normal;
  word-break: break-all;
}

::v-deep .el-checkbox__input {
  width: 14px;
  height: 14px;
}

::v-deep .el-checkbox__label {
  width: 130px;
  min-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}

.label {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 109, 204, 1);
  line-height: 18px;
  float: right;
  text-decoration: none;
}

::v-deep .tag {
  height: 400px;
  width: 246px;
  position: relative;
  left: -10px;
  overflow-y: auto;

  .el-tag {
    margin-right: 10px;
    margin-top: 10px;
    border: none;
    height: 24px;
    line-height: 24px;
  }
}

.border {
  border: 2px solid rgb(0, 102, 255) !important;
}
</style>